<script setup lang="ts">
import { computed, inject, Ref } from 'vue';
import { RouterLink } from 'vue-router';

const props = defineProps<{
  noClose?: Boolean;
  noIcon?: Boolean;
  disabled?: Boolean;
  inMenu?: String;
  toMenu?: String;

  to?: String;

  href?: String;
  target?: String;
}>();

const emit = defineEmits(['click']);

const close = inject('dropdown-close') as () => void;
const menu = inject('dropdown-menu') as Ref<string>;

const isVisible = computed(() => {
  if (!menu.value && !props.inMenu) return true;
  if (menu.value == props.inMenu) return true;
  return false;
});

const component = computed(() => {
  if (props.to != null) {
    return {
      is: RouterLink,
      attr: { to: props.to },
    };
  }

  if (props.href != null) {
    return {
      is: 'a',
      attr: { href: props.href, target: props.target },
    };
  }

  return {
    is: 'button',
    attr: { type: 'button' },
  };
});

function onClick() {
  emit('click');
  if (props.toMenu) menu.value = props.toMenu;
  else if (!props.noClose) close();
}
</script>

<template>
  <component
    v-if="isVisible"
    :is="component.is"
    v-bind="component.attr"
    class="dropdown-item"
    :class="{ 'dropdown-item--no-icon': noIcon, 'dropdown-item--disabled': disabled }"
    v-on:click="onClick()"
  >
    <slot name="icon" v-if="!noIcon">
      <div class="icon-spacer"></div>
    </slot>

    <slot></slot>
  </component>
</template>

<style scoped>
.dropdown-item {
  display: grid;
  grid-template-columns: [icon] 2rem [content] 1fr;
  width: 100%;
  border: none;
  background-color: transparent;
  text-decoration: none;
  color: inherit;
  text-align: left;
  padding: var(--gap-md);
  gap: var(--gap-md);
  align-items: center;

  &:hover {
    background-color: var(--color-hover);
  }
}

.dropdown-item--no-icon {
  grid-template-columns: [content] 1fr;
}

.dropdown-item--disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
